// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  Body,
  Button,
  Card,
  Column,
  Illustration,
  LgPadding,
  MdSpacing,
  PressableAltoIcon,
  Row,
  useScreenSize,
} from '@alto/design-system';
import { type AppPromoCardType } from '@alto/scriptdash/alto/patients/engagement/types/v1/app_promo';
import React from 'react';
import { Linking } from 'react-native';
import styled from 'styled-components/native';
import { getPromoCardBackgroundColor } from './utils';

type Props = {
  title: string;
  cardType?: AppPromoCardType | null;
  content?: string;
  ctaText?: string | null;
  imageURL?: string | null;
  onClose?: () => void;
  routeURL?: string | null;
};

const LeftContent = styled(Column)`
  max-width: 70%;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const PromoCard = ({ title, cardType, content, ctaText = 'Done', imageURL, onClose, routeURL }: Props) => {
  const { isMDScreenOrBigger } = useScreenSize();
  const backgroundColor = getPromoCardBackgroundColor(cardType || 'primary_lighter');

  const handlePress = () => {
    if (routeURL) {
      Linking.openURL(routeURL);
    }
  };

  return (
    <Card
      title={title}
      style={{ backgroundColor }}
      RightContent={
        onClose ? (
          <PressableAltoIcon
            name="close"
            onPress={onClose}
            accessibilityLabel={'close promo card'}
            testID={'close-promo-card'}
          />
        ) : undefined
      }
    >
      <LgPadding topPadding={SPACING.STATIC.NONE}>
        <Row spaceBetween>
          <LeftContent spaceBetween>
            {content ? <Body>{content}</Body> : null}
            <MdSpacing />
            {ctaText && routeURL ? (
              <>
                <Button
                  type="primary"
                  onPress={handlePress}
                  label={ctaText}
                  width="inline"
                  small
                />
              </>
            ) : null}
          </LeftContent>
          <Column>
            {imageURL ? (
              <Illustration
                source={imageURL}
                size={isMDScreenOrBigger ? 'xl' : 'lg'}
                accessibilityLabel="promo card"
              />
            ) : null}
          </Column>
        </Row>
      </LgPadding>
    </Card>
  );
};

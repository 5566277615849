// @owners { team: patients-team }
import React, { PureComponent } from 'react';
import InfoRow from '~web/components/InfoRow';

type Props = {
  readonly medicationName: string;
};

export default class SignatureRequiredTag extends PureComponent<Props> {
  render() {
    const { medicationName } = this.props;

    return (
      <InfoRow
        icon="signature-small"
        infoText={`${medicationName} is a controlled substance and requires a signature upon delivery.`}
      >
        Signature required upon delivery
      </InfoRow>
    );
  }
}

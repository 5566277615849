/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/unbound-method */
import { type Middleware } from 'redux';
import {
  type ActionCreateAddressSucceeded,
  type ActionUpdateAddressSucceeded,
  CREATE_ADDRESS_SUCCEEDED,
  UPDATE_ADDRESS_SUCCEEDED,
} from '~shared/actions/addresses';
import {
  ADD_TO_CART,
  type ActionAddToCart,
  type ActionClearCart,
  type ActionClearEditOrder,
  type ActionInitExistingOrder,
  type ActionRemoveFromCart,
  type ActionSelectPaymentType,
  type ActionUpdateCart,
  type ActionUpdateEditOrder,
  CLEAR_CART,
  CLEAR_EDIT_ORDER,
  INIT_EXISTING_ORDER,
  REMOVE_FROM_CART,
  SELECT_PAYMENT_TYPE,
  UPDATE_CART,
  UPDATE_EDIT_ORDER,
  clearCart,
  clearDeliveryWindows,
  fetchWindows,
  turnOnSplitPayment,
  updateOrder,
} from '~shared/actions/cart';
// eslint-disable-next-line import/no-deprecated
import { fetchDeliveries } from '~shared/actions/deliveries';
// eslint-disable-next-line import/no-deprecated
import { type FetchNextAvailableDatesOptions, fetchNextAvailableDates } from '~shared/actions/nextAvailableDates';
import {
  type ActionCreatePaymentMethodSucceeded,
  CREATE_PAYMENT_METHOD_SUCCEEDED,
} from '~shared/actions/paymentMethods';
// eslint-disable-next-line import/no-deprecated
import { autobillPrescription, fetchPrescriptions } from '~shared/actions/prescriptions';
// eslint-disable-next-line import/no-deprecated
import { fetchOrderPricing } from '~shared/actions/pricing';
import { getItemsInOrder__DEPRECATED } from '~shared/features/checkout/selectors/getItems';
import getShouldShowCrossSellPage__DEPRECATED from '~shared/features/checkout/selectors/getShouldShowCrossSellPage';
import { deleteAddOnPrescription } from '~shared/features/essentials/actions';
import { type ReduxDispatch, type ReduxStateShared } from '~shared/types';

const allowedTypes = {
  [ADD_TO_CART]: true,
  [REMOVE_FROM_CART]: true,
  [UPDATE_EDIT_ORDER]: true,
  [UPDATE_CART]: true,
  [SELECT_PAYMENT_TYPE]: true,
  [INIT_EXISTING_ORDER]: true,
  [CREATE_ADDRESS_SUCCEEDED]: true,
  [UPDATE_ADDRESS_SUCCEEDED]: true,
  [CREATE_PAYMENT_METHOD_SUCCEEDED]: true,
  [CLEAR_CART]: true,
  [CLEAR_EDIT_ORDER]: true,
};

const ORDER_PRICING_UPDATE_CART_KEYS = {
  payment_method_id: true,
  second_payment_method_id: true,
  shipment_payment_methods: true,
  include_sharps_container: true,
  items: true,
  selected_payment_types: true,
  tip_amount: true,
  tip_payment_method_id: true,
  date: true,
};

type Actions =
  | ActionAddToCart
  | ActionClearCart
  | ActionClearEditOrder
  | ActionCreateAddressSucceeded
  | ActionCreatePaymentMethodSucceeded
  | ActionInitExistingOrder
  | ActionUpdateAddressSucceeded
  | ActionUpdateCart
  | ActionUpdateEditOrder
  | ActionSelectPaymentType
  | ActionRemoveFromCart;

const cart: Middleware<unknown, ReduxStateShared, ReduxDispatch> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: Actions) => {
    const result = next(action);

    if (!allowedTypes[action.type]) {
      return result;
    }

    const state = getState();

    switch (action.type) {
      case INIT_EXISTING_ORDER:
        if (action?.payload?.order?.date || action.payload.order?.address_id) {
          dispatch(fetchWindows());
        }

        if (action?.payload?.order?.shipment_payment_methods?.length === 2) {
          dispatch(turnOnSplitPayment());
        }

        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchOrderPricing());
        break;
      case UPDATE_EDIT_ORDER:
      case UPDATE_CART: {
        const items = getItemsInOrder__DEPRECATED(state);
        if (!items.length) break;

        if (action.payload.date || action.payload.address_id) {
          dispatch(fetchWindows());
        }

        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
        if (Object.keys(action.payload).some((key) => ORDER_PRICING_UPDATE_CART_KEYS[key])) {
          // eslint-disable-next-line import/no-deprecated
          dispatch(fetchOrderPricing());
        }

        break;
      }

      case ADD_TO_CART: {
        const { payload } = action;
        const { server_side_cart_origin, billing_overrides, resetOrderDate, ...itemKey } = payload;

        if (server_side_cart_origin) {
          break;
        }

        const items = getItemsInOrder__DEPRECATED(state);
        const item = items.find((item) => item.equals(itemKey));
        const prescription = item?.prescription;

        if (prescription) {
          // Cross-sell items are derived from unbundled products.
          // Only fetch dates for relevant prescriptions when we do not need to show cross-sell products
          const nextAvailableDateOptions: FetchNextAvailableDatesOptions = {};
          if (!getShouldShowCrossSellPage__DEPRECATED(state)) {
            nextAvailableDateOptions.unbundledPrescriptionIDs = [prescription.id];
          }

          // eslint-disable-next-line import/no-deprecated, promise/catch-or-return, promise/always-return
          dispatch(autobillPrescription(prescription.id, billing_overrides)).then(() => {
            // eslint-disable-next-line import/no-deprecated
            dispatch(fetchOrderPricing());
            // The correct order is to refresh deliveries after autobill, before refreshing prescriptions.
            // Why? Because refreshing prescriptions includes logic to remove any prescriptions from cart that aren't
            // ready to schedule. (see removeUnschedulablePrescriptionsFromCart).
            // A delivery will appear not ready to schedule if it's refreshed during autobill and not after.
            // eslint-disable-next-line import/no-deprecated, promise/catch-or-return, promise/no-nesting, promise/always-return
            dispatch(fetchDeliveries()).then(() => {
              // eslint-disable-next-line import/no-deprecated
              dispatch(fetchPrescriptions());
            });
            // eslint-disable-next-line import/no-deprecated, promise/catch-or-return, promise/no-nesting
            dispatch(fetchNextAvailableDates(nextAvailableDateOptions)).then(() => {
              // eslint-disable-next-line promise/always-return
              if (resetOrderDate) {
                // resetOrderDate is passed in for flows that are likely to update the next available date to an
                // earlier date. This is currently used for 'Get it sooner' flows, where patients can choose
                // different payment options as a way to get their medication sooner.
                // In these scenarios, we should reset the order date and have select date component
                // calculate the order date based on the new next available date
                dispatch(updateOrder({ date: undefined }));
              }
            });
          });
        }

        dispatch(fetchWindows());

        break;
      }

      case SELECT_PAYMENT_TYPE: {
        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchOrderPricing());
        break;
      }

      case REMOVE_FROM_CART: {
        const { payload } = action;
        const { prescription, resource_type, server_side_cart_origin } = payload;

        if (server_side_cart_origin) {
          break;
        }

        if (resource_type === 'Prescription' && prescription?.is_add_on_otc) {
          try {
            deleteAddOnPrescription(prescription?.id);
          } catch {
            // no op:
            // Deleting the Add On prescription is for garbage collecting purposes on the backend. The client doesn't care about
            // the result of this endpoint, nor does any part of the UI rely on its loading/success/error.
          }
        }

        const items = getItemsInOrder__DEPRECATED(state);

        if (items.length === 0) {
          dispatch(clearCart());
          break;
        }

        // eslint-disable-next-line import/no-deprecated
        dispatch(fetchOrderPricing());
        dispatch(fetchWindows());

        break;
      }

      case CLEAR_EDIT_ORDER:
      case CLEAR_CART: {
        dispatch(clearDeliveryWindows());
        break;
      }

      default:
    }

    return result;
  };

export default cart;
/* eslint-enable sonarjs/cognitive-complexity */
/* eslint-enable @typescript-eslint/unbound-method */

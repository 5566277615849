// @owners { team: patients-team }
import { ActionSheetContext } from '@alto/design-system';
import React, { useContext, useEffect } from 'react';
import { setAnnouncementViewed } from '~shared/actions/ui/announcements';
import { getAnnouncementViewedStatus } from '~shared/selectors/ui/announcements';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { useQueryFetchAnnouncement } from '../queries/useQueryFetchAnnouncement';
import { AnnouncementActionSheet } from './AnnouncementActionSheet';

export const AnnouncementNotifier = () => {
  const dispatch = useDispatchShared();
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { announcement, isSuccess, isFetching, isError } = useQueryFetchAnnouncement();

  // Get announcement viewed status. Will return false if no entry found in redux store.
  const announcementViewed = useSelectorShared((state) => getAnnouncementViewedStatus(state, announcement?.id));

  useEffect(() => {
    if (isFetching || isError) {
      return;
    }

    // Only show when announcement is present in response and has not been
    // marked as viewed yet on the device.
    if (isSuccess && announcement && !announcementViewed) {
      setActiveActionSheet(
        <AnnouncementActionSheet
          title={announcement.title}
          content={announcement.content}
          imageURL={announcement.image_url}
          routeURL={announcement.route_url}
          ctaText={announcement.cta_text}
        />,
      );
      dispatch(setAnnouncementViewed(announcement.id));
    }
  }, [isSuccess, announcement, announcementViewed, isError, isFetching, setActiveActionSheet, dispatch]);

  return null;
};

// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import {
  ActionSheetContext,
  ActionSheetV2,
  Body,
  Button,
  Column,
  H1,
  Illustration,
  LgPadding,
  LgSpacing,
  MdPadding,
  MdSpacing,
} from '@alto/design-system';
import React, { useContext } from 'react';
import { Linking } from 'react-native';

type Props = {
  title: string;
  content: string;
  imageURL?: string | null;
  routeURL?: string | null;
  ctaText?: string | null;
};

export const AnnouncementActionSheet = ({ title, content, imageURL, routeURL, ctaText }: Props) => {
  const { closeActionSheet } = useContext(ActionSheetContext);

  const handlePress = () => {
    if (routeURL) {
      Linking.openURL(routeURL);
    } else {
      closeActionSheet();
    }
  };

  return (
    <ActionSheetV2 analyticsName="app announcement">
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <Column centerHorizontally>
          {imageURL ? (
            <Illustration
              source={imageURL}
              size="xxl"
              accessibilityLabel="Announcement Action Sheet Image"
            />
          ) : null}
          <LgSpacing />
          <H1>{title}</H1>
          <MdSpacing />
          <Body center>{content}</Body>
          <MdSpacing />
          <MdPadding
            leftPadding={SPACING.STATIC.LG}
            rightPadding={SPACING.STATIC.LG}
          >
            <Button
              type="primary"
              onPress={handlePress}
              label={ctaText || 'Ok'}
            />
          </MdPadding>
        </Column>
      </LgPadding>
    </ActionSheetV2>
  );
};

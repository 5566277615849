// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Body, Card, InputRadio, InputRadioGroup, MdSpacing, Toast, ToastContext } from '@alto/design-system';
import React, { useContext, useEffect, useState } from 'react';
import { push } from 'react-router-redux';
// eslint-disable-next-line import/no-deprecated
import { updateOnboardingUser } from '~shared/actions/onboarding';
import {
  ONBOARD_STATE_TARGETED_DIAGNOSES,
  TARGETED_DIAGNOSES,
} from '~shared/features/onboarding/helpers/onboardingRouteHandler';
import { createPatientDiagnosis, deletePatientDiagnosisByCode } from '~shared/helpers/patientDiagnoses';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { OnboardingButtonDesktop, OnboardingButtonMobile } from './OnboardingButton';
import OnboardingPageBackground from './OnboardingPageBackgroundContainer';
import { Header } from '~web/components/HeaderImage';
import { OnboardingContentWrapper } from '~web/components/Page';
import { nextUrl as getNextUrl } from '~web/features/onboarding/helpers/urlHandler';

export const OnboardingTargetedDiagnoses = () => {
  const { addToast } = useContext(ToastContext);
  const { trackEvent, trackPageView } = useAnalytics();
  const dispatch = useDispatchShared();
  const [hasType2Diabetes, setHasType2Diabetes] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const nextUrl = useSelectorShared((state) => getNextUrl(state, TARGETED_DIAGNOSES));

  useEffect(() => {
    dispatch(
      // eslint-disable-next-line import/no-deprecated
      updateOnboardingUser({
        onboard_state: ONBOARD_STATE_TARGETED_DIAGNOSES,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    trackPageView({
      event: EVENTS.MEDICAL_CONDITIONS_PROMPTED,
      params: {
        condition: 'type 2 diabetes',
        origin: 'onboarding',
      },
    });
  }, [trackPageView]);

  const onSubmit = async () => {
    setIsLoading(true);

    trackEvent({
      event: EVENTS.MEDICAL_CONDITIONS_SAVED,
      params: {
        added: hasType2Diabetes,
        condition: 'type 2 diabetes',
        origin: 'onboarding',
      },
    });

    try {
      if (hasType2Diabetes) {
        await createPatientDiagnosis({ params: { code: 'E11', notes: 'Type 2 diabetes mellitus' } });
      } else {
        await deletePatientDiagnosisByCode({ code: 'E11' });
      }
    } catch {
      addToast(<Toast variant="error">Something went wrong. Please try again.</Toast>);
    } finally {
      dispatch(push(nextUrl));
      setIsLoading(false);
    }
  };

  return (
    <OnboardingPageBackground>
      <OnboardingContentWrapper>
        <div>
          <Header title="Do you have type 2 diabetes?" />
          <MdSpacing />
          <Body
            color={COLORS.TEXT_COLORS.GREY}
            center
          >
            The manufacturer is updating their discount program for Mounjaro. Tell us your diabetes diagnosis below to
            qualify for the best price.
          </Body>
          <Card>
            <InputRadioGroup
              onValueChange={(value: string) => {
                if (value === 'yes') {
                  setHasType2Diabetes(true);
                } else if (value === 'no') {
                  setHasType2Diabetes(false);
                } else {
                  setHasType2Diabetes(null);
                }
              }}
              label="Do you have type 2 diabetes?"
            >
              <InputRadio
                key="yes"
                label="Yes"
                value="yes"
              />
              <InputRadio
                key="no"
                label="No"
                value="no"
              />
            </InputRadioGroup>
          </Card>
          <OnboardingButtonDesktop
            loading={isLoading}
            disabled={hasType2Diabetes === null}
            label="Continue"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
          />
        </div>
      </OnboardingContentWrapper>
      <OnboardingButtonMobile
        loading={isLoading}
        disabled={hasType2Diabetes === null}
        label="Continue"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onSubmit}
      />
    </OnboardingPageBackground>
  );
};

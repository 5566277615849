import { browserHistory } from 'react-router';
import { type RouteParams } from './RouteParams';
import { featureRouteMap } from './featureRouteMap';

export const useNavigation = <RouteName extends keyof RouteParams = 'RouteAppTabNavigator'>() => {
  return {
    /**
     * @param route The native route name that you want to navigate to. Found in libs/shared/lib/nativeRouteNames.ts
     * @param params The params you want to pass. The relationship between the route name and passable params is found in libs/navigation/src/featureRouteMap.ts
     */
    navigate: <NextRouteName extends keyof RouteParams>(route: NextRouteName, params?: RouteParams[NextRouteName]) => {
      let featureRoute;
      if (route === 'RouteAppTabNavigator' && params && 'screen' in params) {
        const tabRouteName = params.screen;
        if (!tabRouteName) return;
        // pass route params for tabs that have them
        if (tabRouteName === 'RouteTabMyMeds') {
          featureRoute = featureRouteMap[tabRouteName](params as RouteParams['RouteTabMyMeds']).web;
        } else {
          featureRoute = featureRouteMap[tabRouteName]().web;
        }
      } else {
        featureRoute = featureRouteMap[route](params).web;
      }
      browserHistory.push(featureRoute);
    },

    /**
     * Get the name of the currently-active route
     */
    getCurrentRouteName: () => browserHistory.getCurrentLocation()?.pathname,

    /**
     * Temp implementation pending update of react-router.
     *
     * Note: accessing url params in web does not work via the getParam hook. Instead, params should be accessed by web components using a Higher OrderComponent called withRouter.
     * Within the component, you can then access the params needed as props.
     * @example
     * export default withRouter(DeliveryDetails)
     *
     * DeliveryDetails component will then have access to props provided by react-router to the component rendered in routes.tsx (e.g. props.params, props.location)
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
    getParam: <RouteKey extends keyof RouteParams[RouteName]>(paramName: RouteKey, fallbackValue?: any) => {
      const params = new URLSearchParams(window.location.search);
      // @ts-expect-error Type 'number' is not assignable to type 'string'.
      return params.get(paramName) || fallbackValue;
    },

    /**
     * No-op on web for now.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setParams: (_params?: Record<string, any>) => undefined,

    /**
     * No-op on web for now.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setOptions: (_options: any) => undefined,

    /**
     * Go to the previous page. NOTE this takes a key arg to maintain react-native compatibility but this does nothing
     * on web, it will always instead take you to the previous page.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    goBack: (_?: string) => {
      browserHistory.goBack();
    },

    /**
     * No-op on web for now.
     */
    canGoBack: () => undefined,

    getRouteName: (routeName: RouteName) => routeName,

    /**
     * @param route The native route name that you want to navigate to. Found in libs/shared/lib/nativeRouteNames.ts
     * @param params The params you want to pass. The relationship between the route name and passable params is found in libs/navigation/src/featureRouteMap.ts
     */
    replace: <T extends keyof RouteParams>(routeName: T, params?: RouteParams[T]) => {
      const featureRoute = featureRouteMap[routeName](params).web;
      browserHistory.replace(featureRoute);
    },
  };
};

export type Navigate = ReturnType<typeof useNavigation>['navigate'];

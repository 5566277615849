// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { MdPadding } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { type AppPromo } from '@alto/scriptdash/alto/patients/engagement/types/v1/app_promo';
import React, { useEffect } from 'react';
import { setPromoDismissed, setPromoViewed } from '~shared/actions/ui/promos';
import { isNative } from '~shared/helpers/isNative';
import { useDispatchShared } from '~shared/store';
import { PromoCard } from '../../engagement';

type Props = {
  readonly promos: AppPromo[];
};

export const PromosSection = ({ promos }: Props) => {
  // We will use the first of eligible promos provided. An eligible promo is one who either hasn't
  // been seen yet, or has a view count less than the max impression count.
  const promo: AppPromo | undefined = promos[0];
  const maxImpressionCount = promo?.max_impression_count || 5;
  const dispatch = useDispatchShared();
  const { value: appEducationEnabled } = Experimentation.useFeatureFlag('app_education');

  // When a promo is present, we will mark it as viewed when the component is mounted and rendered.
  useEffect(() => {
    if (!appEducationEnabled || !promo) return;
    dispatch(setPromoViewed({ promoID: promo.id }));
  }, [appEducationEnabled, dispatch, promo]);

  if (!appEducationEnabled || !promo) {
    return null;
  }

  const promoCard = (
    <PromoCard
      title={promo.title}
      cardType={promo.card_type}
      content={promo.content}
      ctaText={promo.cta_text}
      imageURL={promo.image_url}
      routeURL={promo.route_url}
      onClose={() => {
        // If users dismisses manually, set view count to max so promo doesn't show again.
        dispatch(setPromoDismissed({ promoID: promo.id, maxImpressionCount }));
      }}
    />
  );

  return isNative ? (
    <MdPadding
      topPadding={SPACING.STATIC.NONE}
      bottomPadding={SPACING.STATIC.NONE}
    >
      {promoCard}
    </MdPadding>
  ) : (
    promoCard
  );
};

import { type CartItem } from '@alto/orders_api/types/v1/cart_item';
import { type PriceOption } from '@alto/scriptdash/alto/pricing/patients/v3/pricing_endpoint';
import { memoize } from 'lodash';
import pluralize from 'pluralize';
import { type Item, type ItemKey, type Order, type ValidationError } from './types';
import {
  MIN_PAYMENT_AMOUNT,
  NO_PAYMENT_METHOD_SELECTED,
  PAYMENT_METHOD_ERROR_MESSAGES,
  PICKUP_NO_PAY_AT_PICKUP,
  SPLIT_AMOUNTS_NOT_MATCHED,
  UNEDITABLE_SPLIT_PAYMENTS,
} from '~shared/features/checkout/constants';
import { SOURCES as TIP_SOURCES } from '~shared/features/courier-tips/AnalyticsProps';
import { PAYMENT_METHOD_DATA_TYPES } from '~shared/features/payment-methods/constants';
import { formatDollarsWithPlaceholder, roundToTwo } from '~shared/helpers/currency';
import { formatRelativeDate } from '~shared/helpers/date';
import { formatListMaxTwo } from '~shared/helpers/list';
import { type PaymentMethod, type Prescription } from '~shared/types';

export function itemEquals(item1: ItemKey, item2: ItemKey) {
  return item1.resource_type === item2.resource_type && item1.resource_id === item2.resource_id;
}

export function itemNotEquals(item1: ItemKey, item2: ItemKey) {
  return !itemEquals(item1, item2);
}

export function prescriptionToItemKey(prescriptionID: number): ItemKey {
  return {
    resource_type: 'Prescription',
    resource_id: prescriptionID,
  };
}

export function deliveryToItemKey(deliveryID: number): ItemKey {
  return {
    resource_type: 'Delivery',
    resource_id: deliveryID,
  };
}

export function getItemMedNames(items: Item[]): string | null | undefined {
  return formatListMaxTwo(items.map((i) => i.displayName()));
}

export function getCartItemMedNames(items: CartItem[]): string | null | undefined {
  return formatListMaxTwo(items.map((i) => i.name));
}

export const getPrescriptionInfoText = (
  prescription: Prescription,
  familyMemberName: string | null | undefined,
): string => {
  let prescriptionInfoText = '';
  const refillsRemaining = prescription.refills_remaining;
  const dosageForm = prescription.product_friendly_dosage_form || prescription.dosage_form;

  if (dosageForm) {
    prescriptionInfoText += dosageForm;
  }

  if (refillsRemaining) {
    prescriptionInfoText += `${prescriptionInfoText.length ? ' • ' : ''}${refillsRemaining} ${pluralize(
      'Refill',
      refillsRemaining,
    )} Left`;
  }

  if (familyMemberName) {
    prescriptionInfoText += `${prescriptionInfoText.length ? ' • ' : ''}For ${familyMemberName}`;
  }

  return prescriptionInfoText;
};

export const getLastFilledText = (prescription: Prescription): string => {
  if (!prescription.last_delivered_at) {
    return '';
  }

  let lastFilledText = `Last filled ${formatRelativeDate(prescription.last_delivered_at, { includeRelative: true })}`;

  if (prescription.days_supply) {
    lastFilledText += ` (${prescription.days_supply} day supply)`;
  }

  return lastFilledText;
};

export const getCrossSellHeader = (numPrescriptions: number) => {
  return `Need ${pluralize('this', numPrescriptions)} ${pluralize('refill', numPrescriptions)}?`;
};

export const getCrossSellSubHeader = (numPrescriptions: number) => {
  return `Stay on track with your treatment—${pluralize('this', numPrescriptions)} ${pluralize(
    'medication',
    numPrescriptions,
  )} ${pluralize('is', numPrescriptions)} ready to be refilled`;
};

/**
 * Returns the item quantity for an Add On OTC prescription. For example, if the prescription has quantity of 240
 * and the product quantity is 60, then the item quantity returned will be 4.
 */
export const getItemQuantityForAddOnOtc = (prescription: Prescription, product_quantity: number | undefined) => {
  // prescription.quantity should never be undefined here but doing this to appease flow
  return Math.round(prescription.quantity && product_quantity ? prescription.quantity / product_quantity : 1);
};

export const getCashLessThanInsuranceSavings = (priceOptions: PriceOption[]) => {
  const withInsurancePrice = priceOptions.find((option) => option.type === 'with_insurance')?.price ?? null;
  const withoutInsurancePrice = priceOptions.find((option) => option.type === 'without_insurance')?.price ?? null;
  const cashLessThanInsurance =
    withInsurancePrice && withoutInsurancePrice ? withInsurancePrice - withoutInsurancePrice : null;
  return cashLessThanInsurance
    ? `Saving ${formatDollarsWithPlaceholder(cashLessThanInsurance)} paying without insurance`
    : null;
};

export const getTipAnalyticsProps = memoize(
  ({
    shipmentID,
    cartResourceIds,
    isEditingOrder,
    facilityId,
  }: {
    shipmentID: string | number | null | undefined;
    cartResourceIds: (string | number)[] | undefined;
    isEditingOrder: boolean;
    facilityId: number | undefined;
  }) => {
    return {
      shipment_id: shipmentID ?? undefined,
      prescription_ids: shipmentID ? undefined : cartResourceIds,
      source: isEditingOrder ? TIP_SOURCES.EDIT_DELIVERY : TIP_SOURCES.PRE_DELIVERY,
      facility_id: facilityId,
    };
  },
  (props) => JSON.stringify(props),
);

type AvailableMailers = {
  isFedEx: boolean;
  isUSPS: boolean;
};

export const getMailerText = ({ isFedEx, isUSPS }: AvailableMailers) => {
  const mailers: string[] = [];
  if (isFedEx) {
    mailers.push('FedEx');
  }
  if (isUSPS) {
    mailers.push('USPS');
  }

  return mailers.join(' and ');
};

export const isValidPaymentMethod = (
  paymentMethodID: number | null | undefined,
  paymentMethods: PaymentMethod[],
  isSplitPaymentEnabled?: boolean,
) =>
  !!paymentMethods.find(
    (paymentMethod) =>
      paymentMethod.id === paymentMethodID &&
      (!isSplitPaymentEnabled || paymentMethod.type !== PAYMENT_METHOD_DATA_TYPES.PLAID_DATA),
  );

export const getCheckoutPaymentMethodErrors = ({
  order,
  paymentMethods,
  isPaymentMethodRequired,
  isSplitPaymentEnabled,
}: {
  order: Order;
  paymentMethods: PaymentMethod[];
  isPaymentMethodRequired: boolean;
  isSplitPaymentEnabled: boolean;
}) => {
  const { payment_method_id: paymentMethodID, shipment_payment_methods: shipmentPaymentMethods } = order ?? {};

  const errors: ValidationError[] = [];

  // eslint-disable-next-line sonarjs/no-collapsible-if
  if (isPaymentMethodRequired) {
    if (
      !isValidPaymentMethod(paymentMethodID, paymentMethods, isSplitPaymentEnabled) &&
      !shipmentPaymentMethods?.length
    ) {
      errors.push({
        key: NO_PAYMENT_METHOD_SELECTED,
        message: 'Please select a payment method for your medications.',
      });
    }
  }

  return { paymentMethodValidationErrors: errors };
};

export const getCheckoutPaymentAmountErrors = ({
  order,
  isSplitPaymentEnabled,
  splitPaymentsTotal,
  isPaymentMethodRequired,
}: {
  order: Order;
  isSplitPaymentEnabled: boolean;
  splitPaymentsTotal: number | null;
  isPaymentMethodRequired: boolean;
}) => {
  const {
    payment_method_amount,
    payment_method_id: firstPaymentMethodID,
    second_payment_method_amount,
    second_payment_method_id: secondPaymentMethodID,
  } = order ?? {};
  const firstPaymentMethodAmount = Number(payment_method_amount);
  const secondPaymentMethodAmount = Number(second_payment_method_amount);

  const errors: ValidationError[] = [];

  if (!isPaymentMethodRequired) return { paymentAmountValidationErrors: errors };
  if (!splitPaymentsTotal && firstPaymentMethodID && secondPaymentMethodID) {
    errors.push({
      key: UNEDITABLE_SPLIT_PAYMENTS,
      message: PAYMENT_METHOD_ERROR_MESSAGES.uneditable_split_payments,
    });
  }

  if (isSplitPaymentEnabled && splitPaymentsTotal) {
    if (!secondPaymentMethodID) {
      errors.push({
        key: NO_PAYMENT_METHOD_SELECTED,
        message: PAYMENT_METHOD_ERROR_MESSAGES.payment_method_does_not_exist,
        isSecondaryField: true,
      });
    }

    if (firstPaymentMethodAmount < MIN_PAYMENT_AMOUNT || secondPaymentMethodAmount < MIN_PAYMENT_AMOUNT) {
      errors.push({
        key: SPLIT_AMOUNTS_NOT_MATCHED,
        message: PAYMENT_METHOD_ERROR_MESSAGES.minimum_amount,
        isSecondaryField: secondPaymentMethodAmount < MIN_PAYMENT_AMOUNT,
      });
    }

    if (roundToTwo(firstPaymentMethodAmount + secondPaymentMethodAmount) !== splitPaymentsTotal) {
      errors.push({
        key: SPLIT_AMOUNTS_NOT_MATCHED,
        message: PAYMENT_METHOD_ERROR_MESSAGES.not_matched_full_amount,
        isSecondaryField: true,
      });
    }

    if (firstPaymentMethodID === secondPaymentMethodID) {
      errors.push({
        key: SPLIT_AMOUNTS_NOT_MATCHED,
        message: PAYMENT_METHOD_ERROR_MESSAGES.different_payment_methods,
        isSecondaryField: true,
      });
    }
  }

  return { paymentAmountValidationErrors: errors };
};

export const getPickupPayInAdvanceErrors = ({
  order,
  isPaymentMethodRequired,
}: {
  order: Order;
  isPaymentMethodRequired: boolean;
}) => {
  const { delivery_method, pay_at_pickup } = order ?? {};
  const errors: ValidationError[] = [];

  if (delivery_method === 'pickup' && isPaymentMethodRequired && typeof pay_at_pickup === 'undefined') {
    errors.push({
      key: PICKUP_NO_PAY_AT_PICKUP,
      message: 'Please select a payment option for your pickup order.',
    });
  }

  return { pickupPayInAdvanceErrors: errors };
};

export const getPaymentErrors = ({
  order,
  paymentMethods,
  isPaymentMethodRequired,
  isSplitPaymentEnabled,
  splitPaymentsTotal,
}: {
  order: Order;
  paymentMethods: PaymentMethod[];
  isPaymentMethodRequired: boolean;
  isSplitPaymentEnabled: boolean;
  splitPaymentsTotal: number | null;
}) => {
  const { paymentMethodValidationErrors } = getCheckoutPaymentMethodErrors({
    order,
    paymentMethods,
    isPaymentMethodRequired,
    isSplitPaymentEnabled,
  });
  const { paymentAmountValidationErrors } = getCheckoutPaymentAmountErrors({
    order,
    isSplitPaymentEnabled,
    splitPaymentsTotal,
    isPaymentMethodRequired,
  });
  const { pickupPayInAdvanceErrors } = getPickupPayInAdvanceErrors({
    order,
    isPaymentMethodRequired,
  });

  return [...paymentMethodValidationErrors, ...paymentAmountValidationErrors, ...pickupPayInAdvanceErrors];
};

// @owners { team: patients-team }
import { COLORS, SIZES } from '@alto/design-library-tokens';
import { AltoIcon, Avatar, Row as DSRow } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import {
  Column,
  Loader,
  Row,
  SimpleContainer,
  SimpleContainerHeader,
  SimpleContainerSection,
  StaticTextBlock,
  palette,
  spacing,
} from '@alto/pocky';
import React, { PureComponent, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { type ModalType } from '~shared/actions/modal';
import { PRONOUNS } from '~shared/constants';
import { normalizeDateString } from '~shared/helpers/date';
import { getDisplayFullName } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type S3Source, type User } from '~shared/types';
import MedicalInfoModal from './MedicalInfoModalContainer';
import { PersonalInfoModal } from './PersonalInfoModal';
import { ProfileInsurances } from './ProfileInsurances';
import Breadcrumbs from '~web/components/Breadcrumbs';
import { PageBackground } from '~web/components/Page';
import PageHeader from '~web/components/PageHeader';

type Props = {
  readonly openModal: (name: ModalType) => void;
  readonly updateAvatar: (image: S3Source) => void;
  readonly user: User;
  readonly isPatientsOtherScriptsOn: boolean | null | undefined;
};

const PatientPhoto = styled.div<{ imgUrl?: string }>`
  ${({ imgUrl }) =>
    imgUrl &&
    `
    background-image: url(${imgUrl});
    background-size: 150% auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: ${palette.grey2};
  `}
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

const Camera = styled(AltoIcon)`
  color: ${palette.royalBlue};
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

const ProfileUserProfileWrapper = ({ children }: { readonly children: React.ReactNode }) => {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView({
      event: EVENTS.PROFILE__INSURANCE_INFO_VIEWED,
    });
  }, [trackPageView]);

  return <>{children}</>;
};

export default class ProfileUserProfile extends PureComponent<Props> {
  renderAvatarUpload() {
    const { updateAvatar, user } = this.props;

    return (
      <Row horizontallyAlignContent>
        <Dropzone
          multiple={false}
          onDrop={(filesToUpload) => {
            // @ts-expect-error TS(2345): Argument of type 'ImageFile' is not assignable to parameter of type 'S3Source'.
            updateAvatar(filesToUpload[0]);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            // @ts-expect-error Types of property backfaceVisibility are incompatible.
            <DSRow
              {...getRootProps()}
              bottom
            >
              <input {...getInputProps()} />
              {user.avatar_url ? (
                <PatientPhoto
                  imgUrl={user.avatar_url}
                  aria-label="Your uploaded photo"
                />
              ) : (
                <Avatar
                  name={user.first_name ?? 'patient'}
                  initials={`${user.first_name?.charAt(0)?.toUpperCase()}${user.last_name?.charAt(0)?.toUpperCase()}`}
                  size={SIZES.AVATAR.LG.value}
                  backgroundColor={COLORS.BACKGROUND_COLORS.GREY}
                />
              )}
              <Camera name="camera" />
            </DSRow>
          )}
        </Dropzone>
      </Row>
    );
  }

  renderPersonalInfo() {
    const { user } = this.props;

    return (
      <>
        <SimpleContainerSection>
          <StaticTextBlock
            label="First & last name"
            input={{
              value: (user.first_name && user.last_name && `${user.first_name} ${user.last_name}`) || 'Full name',
            }}
          />
        </SimpleContainerSection>
        {user.preferred_first_name ? (
          <SimpleContainerSection>
            <StaticTextBlock
              label="Preferred name"
              input={{
                value: user.preferred_first_name || 'Unspecified',
              }}
            />
          </SimpleContainerSection>
        ) : null}
        <SimpleContainerSection>
          <StaticTextBlock
            label="Date of birth"
            input={{
              value: normalizeDateString(user.date_of_birth || '') || 'Date of birth',
            }}
          />
        </SimpleContainerSection>
        <SimpleContainerSection>
          <StaticTextBlock
            label="Sex"
            input={{
              value: user.gender || 'Unspecified',
            }}
          />
        </SimpleContainerSection>
        {user.preferred_pronoun ? (
          <SimpleContainerSection>
            <StaticTextBlock
              label="Pronouns"
              input={{
                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expression of type 'string' can't be used to index type... (Delete me to see the full error)
                value: PRONOUNS[user.preferred_pronoun] || 'Unspecified',
              }}
            />
          </SimpleContainerSection>
        ) : null}
        <SimpleContainerSection>
          <StaticTextBlock
            label="Photo ID"
            input={{
              value: user.first_name && user.last_name && user.photo_id_url ? 'On file' : 'No photo ID on file',
            }}
          />
        </SimpleContainerSection>
      </>
    );
  }

  renderMedicalInfo() {
    const {
      user: { allergies, medical_conditions, onboarding_other_scripts, other_scripts_list },
      isPatientsOtherScriptsOn,
    } = this.props;
    let otherMedListString = '';

    if (isPatientsOtherScriptsOn) {
      otherMedListString =
        other_scripts_list && other_scripts_list?.length > 0
          ? other_scripts_list?.join(', ')
          : 'No Other Medications Listed'; // title case to match above string values from server
    }

    return (
      <>
        <SimpleContainerSection>
          <StaticTextBlock
            label="Allergies"
            input={{
              value: allergies || 'No allergies on file',
            }}
          />
        </SimpleContainerSection>
        <SimpleContainerSection>
          <StaticTextBlock
            label="Medical conditions"
            input={{
              value: medical_conditions || 'No medical conditions on file',
            }}
          />
        </SimpleContainerSection>
        {isPatientsOtherScriptsOn ? (
          <SimpleContainerSection>
            <StaticTextBlock
              label="Other active medications"
              input={{
                value: onboarding_other_scripts ? otherMedListString : 'No other medications on file',
              }}
            />
          </SimpleContainerSection>
        ) : null}
      </>
    );
  }

  render() {
    const { openModal, user } = this.props;

    if (!user) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    const name = getDisplayFullName(user);
    const breadcrumbs = [
      {
        text: 'Account',
        to: '/me',
      },
      {
        text: name || '',
      },
    ];
    return (
      <ProfileUserProfileWrapper>
        <PageBackground>
          <Column spacing={spacing.mdSpacing}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <PageHeader>{name || ''}</PageHeader>
            {this.renderAvatarUpload()}
            <SimpleContainer>
              <SimpleContainerHeader
                linkText="Edit"
                onClick={() => {
                  openModal('PERSONAL_INFO_MODAL');
                }}
              >
                Personal Info
              </SimpleContainerHeader>
              {this.renderPersonalInfo()}
            </SimpleContainer>
            <SimpleContainer>
              <SimpleContainerHeader
                linkText="Edit"
                onClick={() => {
                  openModal('MEDICAL_INFO_MODAL');
                }}
              >
                Medical Info
              </SimpleContainerHeader>
              {this.renderMedicalInfo()}
            </SimpleContainer>
            <ProfileInsurances userID={user.id} />
          </Column>
          <PersonalInfoModal />
          <MedicalInfoModal />
        </PageBackground>
      </ProfileUserProfileWrapper>
    );
  }
}
